import React from 'react';

import { ACCENT_COLOR } from '@src/config';

import { Content } from '../Content';

export const Loader: React.FC<{
    height: number;
    className?: string;
}> = ({ height, className }) => {
    return (
        <div className={`w-${height} h-${height} ${className || 'mx-auto'}`}>
            <svg
                version="1.1"
                id="L3"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 0 0"
                xmlSpace="preserve"
            >
                <circle
                    fill="none"
                    stroke={ACCENT_COLOR}
                    strokeWidth="4"
                    cx="50"
                    cy="50"
                    r="44"
                    style={{ opacity: 0.5 }}
                />
                <circle
                    fill={ACCENT_COLOR}
                    stroke="rbga(255,255,255,0.05)"
                    strokeWidth="1"
                    cx="8"
                    cy="54"
                    r="6"
                >
                    <animateTransform
                        attributeName="transform"
                        dur="1s"
                        type="rotate"
                        from="0 50 48"
                        to="360 50 52"
                        repeatCount="indefinite"
                    />
                </circle>
            </svg>
        </div>
    );
};
export const PageLoader: React.FC<{ text: string }> = ({ text }) => (
    <Content>
        <div className="text-center my-32" data-testid="page-loader">
            <Loader height={20} />
            <h5 className="mt-8">{text}</h5>
        </div>
    </Content>
);
