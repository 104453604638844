import React from 'react';
import Link from 'next/link';

import { Button } from '@src/components/Button';
import { formatChainScanLink, shortenHex } from '@src/utils/web3';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';

export const Account: React.FC = () => {
    const { account, connecting, connect } = useWeb3Onboard();

    if (!account) {
        return (
            <div className="p-3 md:p-0">
                <Button
                    id="connect-wallet"
                    onClick={() => connect(undefined)}
                    loading={connecting}
                    className="w-32"
                >
                    <p className="text-sm cursor-pointe">Connect wallet</p>
                </Button>
            </div>
        );
    }

    return (
        <Link href={formatChainScanLink('Account', account)}>
            <a target="_blank" rel="noopener noreferrer">
                <Button className="hover:underline w-32">
                    <span className="flex items-center">
                        <img
                            src="/icons/Tick-icon.svg"
                            className="mx-auto w-1 h-1"
                            style={{ width: '12px', height: '12px' }}
                        />
                        <div
                            className="flex-1 ml-2"
                            data-testid="wallet-address"
                        >
                            {shortenHex(account, 2)}
                        </div>
                    </span>
                </Button>
            </a>
        </Link>
    );
};
