import React from 'react';

import { ContentProps } from '@src/ts/props';

export const Content: React.FC<ContentProps> = ({ children, className }) => {
    return (
        <div
            className={`flex-1 container max-w-screen-xl mx-auto p-3 md:p-5 text-left text-white ${
                className || ''
            }`}
        >
            {children}
        </div>
    );
};
