import { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Button } from '@src/components/Button';
import {
    CHAIN_ID,
    COMPANY_LOGO,
    COMPANY_LINK,
    COMPANY_NAME,
    LOGO_WIDTH,
    NETWORK,
    STAKING_MODULES,
} from '@src/config';
import { getContractByAddress } from '@src/contracts';

import { cancellablePromise } from '@src/utils/promise';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';

import { Account } from './Account';
import { isOwner } from '@src/utils/web3';
import { contract_types } from '@src/constants';

const Header: React.FC = () => {
    const router = useRouter();

    const { account, disconnect, wallet, connectedChain } = useWeb3Onboard();

    const [owner, setOwner] = useState('');

    const { address, type } = STAKING_MODULES[0];

    const staking = getContractByAddress(address, contract_types[type]);

    useEffect(() => {
        if (account && staking) {
            const p = staking.owner();
            const { cancel, promise } = cancellablePromise(p);
            promise.then((o: string) => setOwner(o)).catch(() => true);
            return cancel;
        }
    }, [account]);

    const is_owner = useMemo(() => isOwner(account, owner), [account, owner]);
    const is_in_management = router.asPath.includes('manage');
    const is_correct_chain =
        '0x' + CHAIN_ID.toString(16) === connectedChain?.id;

    return (
        <nav>
            {account && !is_correct_chain && (
                <div className="w-full bg-level-two border border-white border-opacity-5 px-5 py-3 text-center text-primary">
                    You are connected to the wrong network. Please change to{' '}
                    {NETWORK.network_name}
                </div>
            )}
            <div className="max-w-screen-xl mx-auto py-3 px-4 flex items-center justify-between">
                <Link href={`${COMPANY_LINK}`}>
                    <a
                        target={
                            COMPANY_LINK.includes('http') ? '_blank' : undefined
                        }
                        ref="noopener,noreferrer"
                    >
                        <img
                            src={COMPANY_LOGO}
                            className="mx-auto"
                            alt={COMPANY_NAME}
                            width={LOGO_WIDTH}
                        />
                    </a>
                </Link>
                <div className="flex items-center space-x-2 md:space-x-4">
                    {is_owner && (
                        <Link href={is_in_management ? '/' : '/manage'}>
                            <a className="hover:underline" data-testid="manage">
                                {is_in_management ? 'Go to pools' : 'Manage'}
                            </a>
                        </Link>
                    )}
                    {account && (
                        <Button
                            className="h-10"
                            onClick={() => disconnect(wallet)}
                            dark
                            id="logout-btn"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                />
                            </svg>
                        </Button>
                    )}

                    <Account />
                </div>
            </div>
        </nav>
    );
};

export default Header;
