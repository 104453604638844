import { init } from '@web3-onboard/react';

import {
    CHAIN_ID,
    getRPCUrl,
    COMPANY_NAME,
    ONBOARD_LOGO,
    COMPANY_LOGO,
} from './config';

import { NETWORK } from './config';

import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect';
import ledgerModule from '@web3-onboard/ledger';
import gnosisModule from '@web3-onboard/gnosis';

const injected = injectedModule();
const ledger = ledgerModule();
const wallet_connect = walletConnectModule({ connectFirstChainId: true });
const gnosis = gnosisModule();

const web3onboard = init({
    wallets: [injected, ledger, wallet_connect, gnosis],
    chains: [
        {
            id: '0x' + CHAIN_ID.toString(16),
            token: NETWORK.symbol,
            label: NETWORK.network_name,
            rpcUrl: getRPCUrl(),
        },
    ],
    appMetadata: {
        name: `${COMPANY_NAME} staking`,
        icon: ONBOARD_LOGO || COMPANY_LOGO,
        description: `The official ${COMPANY_NAME} staking platform.`,
        recommendedInjectedWallets: [
            { name: 'MetaMask', url: 'https://metamask.io' },
        ],
    },
    accountCenter: {
        desktop: { enabled: false, containerElement: 'body' },
        mobile: { enabled: false, containerElement: 'body' },
    },
});

export default web3onboard;
