export const NETWORKS = {
    1: {
        rpc: ['https://mainnet.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a'],
        symbol: 'ETH',
        explorer_url: 'etherscan.com',
        network_name: 'Ethereum Mainnet',
    },
    3: {
        rpc: ['https://ropsten.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a'],
        symbol: 'ETH',
        explorer_url: 'ropsten.etherscan.com',
        network_name: 'Ropsten',
    },
    4: {
        rpc: ['https://rinkeby.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a'],
        symbol: 'ETH',
        explorer_url: 'rinkeby.etherscan.com',
        network_name: 'Rinkeby',
    },
    5: {
        rpc: ['https://goerli.infura.io/v3/6e421ace3971407eb0ad0c12c233db4a'],
        symbol: 'ETH',
        explorer_url: 'georli.etherscan.com',
        network_name: 'Georli',
    },
    56: {
        rpc: [
            'https://winter-bold-frog.bsc.quiknode.pro/50771724bfebdaee06ca2262805d2bde9033a2f0/',
            'https://bsc-dataseed2.binance.org',
        ],
        symbol: 'BNB',
        explorer_url: 'bscscan.com',
        network_name: 'Binance Smart Chain Mainnet',
    },
    97: {
        rpc: [
            'https://fittest-solemn-pond.bsc-testnet.quiknode.pro/1cebe50a86b1cfc4754f637e3ec09add4c4a7388/',
            'https://data-seed-prebsc-1-s2.binance.org:8545/',
        ],
        symbol: 'BNB',
        explorer_url: 'testnet.bscscan.com',
        network_name: 'Binance Smart Chain Testnet',
    },
    137: {
        rpc: ['https://polygon-rpc.com'],
        symbol: 'MATIC',
        explorer_url: 'polygonscan.com',
        network_name: 'Polygon Mainnet',
    },
    80001: {
        rpc: ['https://rpc.ankr.com/polygon_mumbai'],
        symbol: 'MATIC',
        explorer_url: 'mumbai.polygonscan.com',
        network_name: 'Polygon Mumbai Testnet',
    },
    43114: {
        rpc: ['https://api.avax.network/ext/bc/C/rpc'],
        symbol: 'AVAX',
        explorer_url: 'snowtrace.io',
        network_name: 'Avalanche C-Chain',
    },
    43113: {
        rpc: ['https://api.avax-test.network/ext/bc/C/rpc'],
        symbol: 'AVAX',
        explorer_url: 'testnet.snowtrace.io',
        network_name: 'Avalanche Fuji Testnet',
    },
    1337: {
        rpc: ['http://127.0.0.1:8545/'],
        symbol: 'ETH',
        explorer_url: 'testnet.bscscan.com',
        network_name: 'Localhost',
    },
};
