import {
    commify,
    formatUnits,
    parseEther,
    parseUnits,
} from '@ethersproject/units';
import { BigNumberish, BigNumber } from '@ethersproject/bignumber';

import { DEV_WALLETS } from '@src/config';
import { NETWORK } from '@src/config';
import { toast } from 'react-toastify';

const dev_wallets = DEV_WALLETS.map((w): string => w.toLowerCase());

export const isOwner = (account: string, owner: string): boolean => {
    return (
        account &&
        (account.toLowerCase() === owner.toLowerCase() ||
            dev_wallets.includes(account.toLowerCase()))
    );
};

export const bnMin = (bns: BigNumber[]): BigNumber => {
    if (bns.length > 0) {
        let min = bns[0];

        bns.forEach((bn) => {
            if (bn.lt(min)) {
                min = bn;
            }
        });
        return min;
    }
    return null;
};

export const bnSum = (bns: BigNumber[]): BigNumber =>
    bns.reduce((acc, curr) => acc.add(curr), BigNumber.from(0));

export function shortenHex(hex: string, length = 4): string {
    return `${hex.substring(0, length + 2)}…${hex.substring(
        hex.length - length,
    )}`;
}

/**
 *
 * @param {("Account"|"Transaction")} type
 * @param {[number, string]} data
 */
export function formatChainScanLink(
    type: 'Account' | 'Transaction',
    address: string,
): string {
    switch (type) {
        case 'Account': {
            return `https://${NETWORK.explorer_url}/address/${address}`;
        }
        case 'Transaction': {
            return `https://${NETWORK.explorer_url}/tx/${address}`;
        }
    }
}

export const removeCommas = (s: string): string =>
    s
        .split('')
        .filter((c) => c !== ',')
        .join('');

/**
 * @name parseBalance
 *
 * @param {import("@ethersproject/bignumber").BigNumberish} balance
 * @param {number} decimals
 * @param {number} decimalsToDisplay
 *
 * @returns {string}
 */
export const parseBalance = (
    balance: BigNumberish,
    decimals = 18,
    decimalsToDisplay = 2,
): string =>
    commify(Number(formatUnits(balance, decimals)).toFixed(decimalsToDisplay));

export const parseWei = (balance: string, decimals = 18): BigNumber =>
    parseEther(Number(removeCommas(balance)).toFixed(decimals));

export const evaluateWeiBalance = (
    balance: string,
    actual_amount: string,
    decimals = 18,
    decimals_to_display = 2,
): string => {
    const to_subtract = '0.' + '0'.repeat(decimals_to_display - 1) + '1';

    let new_bal = balance;
    const parsed = parseUnits(
        removeCommas(parseBalance(balance, decimals, decimals_to_display)),
        decimals,
    );

    if (parsed.gt(actual_amount)) {
        new_bal = parsed.sub(parseUnits(to_subtract, decimals)).toString();
    }
    return parseBalance(new_bal, decimals, decimals_to_display);
};

export const executeTx = async (
    // eslint-disable-next-line
    promise: Promise<any>,
    message = 'Transaction successfully complete',
    callback?: () => Promise<void>,
): Promise<string> => {
    try {
        toast.info('Awaiting successful transaction');
        const tx = await promise;
        await tx.wait();
        toast.success(`${message || 'Success'}`);
        if (callback) await callback();

        return tx.hash;
    } catch (err) {
        toast.error(err.error?.data?.message || err.message);
    }
    return '';
};
