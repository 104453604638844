import { BigNumber } from '@ethersproject/bignumber';

export interface Dictionary {
    [key: string]: unknown;
}

export interface FormData {
    name: string;
    label: string;
    login?: boolean;
    type:
        | 'text'
        | 'password'
        | 'email'
        | 'number'
        | 'datetime-local'
        | 'checkbox';
    stage?: number;
    required?: boolean;
    limit?: number;
    tooltip?: string;
    helperText?: string;
    placeholder?: string;
    inset?: string;
    step?: number;
    min?: number;
    types?: StakingType[];
}

export interface PoolToken {
    [key: string]: unknown;
    address: string;
    symbol: string;
    logo: string;
    decimals?: number;
    name?: string;
}

export interface StakingPool {
    id: number;
    contract_idx: number;
    type: StakingType;
    reward_rate: string;
    name: string;
    input_token: PoolToken;
    reward_token: PoolToken;
    lock_period: string;
    hardcap: string;
    end_date: string;
    is_withdraw_locked: boolean;
    nft_multiplier?: number;
    nft_multiplier_used?: boolean;
    nft_name?: string;
    address: string;
    total_staked: string;
    user_stake?: string;
    earned_reward?: string;
    claimed_reward?: string;
    last_user_deposit?: string;
    can_claim?: boolean;
    has_stake?: boolean;
    user_multiplier?: string;
}

export interface CompoundStakingPool extends StakingPool {
    user_shares?: string;
    vault?: string;
}

export interface LiquidityStakingPool extends StakingPool {
    second_input_token: PoolToken;
    second_total_staked: string;
    is_native: [boolean, number];
    pair: string;
    second_user_stake?: string;
    lp_amount?: string;
}

export interface NFTStakingPool extends StakingPool {
    max_per_user: number;
    collection_start: number;
    collection_end: number;
    logo: string;
    header_logo: string;
    staked_tokens: NFTData[];
    available_tokens: NFTData[];
    collection_url: string;
}

export interface NFTData {
    id: number;
    data: string;
}

export enum StakingType {
    Compound = 'Compound',
    NFT = 'NFT',
    Liquidity = 'Liquidity',
}

export interface StakingModule {
    address: string;
    type: StakingType;
}

export interface CompoundStakingModule extends StakingModule {
    vault: string;
}

export interface ModuleWithIdx extends StakingModule {
    idx: number;
}
export interface Reserves {
    reserve0: BigNumber;
    reserve1: BigNumber;
}
