import axios from 'axios';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

import { ContractType } from '@src/ts/constants';
import {
    CompoundStakingPool,
    LiquidityStakingPool,
    NFTStakingPool,
    StakingPool,
    StakingType,
} from '@src/ts/interfaces';
import { getContractInterface, getERC721Contract } from '@src/contracts';
import { multiCall } from '@src/utils/multicall';

export const getPool = async (
    current: StakingPool,
    account: string,
    staking: Contract,
) => {
    switch (current.type) {
        case StakingType.NFT:
            return getNFTPool(current as NFTStakingPool, account, staking);
        case StakingType.Liquidity:
            return getLiquidityPool(
                current as LiquidityStakingPool,
                account,
                staking,
            );
        default:
            return getCompoundPool(current, account, staking);
    }
};

const getCompoundPool = async (
    current: CompoundStakingPool,
    account: string,
    staking: Contract,
) => {
    const { contract_idx, vault } = current;
    const vault_iface = getContractInterface(ContractType.Vault);

    const [pool, [user_multiplier], user, [earned_reward], [can_claim]] =
        await multiCall([
            {
                func_name: 'poolInfo',
                target: staking.address,
                iface: staking.interface,
                params: [contract_idx],
            },
            {
                func_name: 'calcMultiplier',
                target: staking.address,
                iface: staking.interface,
                params: [contract_idx, account],
            },
            {
                func_name: 'users',
                target: vault,
                iface: vault_iface,
                params: [contract_idx, account],
            },
            {
                func_name: 'getRewardOfUser',
                target: vault,
                iface: vault_iface,
                params: [account, contract_idx],
            },
            {
                func_name: 'canUnstake',
                target: vault,
                iface: vault_iface,
                params: [account, contract_idx],
            },
        ]);

    const ts = user.lastDepositedTime.mul(1000).toNumber();
    const user_stake = user.totalInvested.toString();
    const user_shares = user.shares.toString();
    const claimed_reward = user.totalClaimed.toString();

    const has_stake = BigNumber.from(user_stake).gt(0);
    const last_user_deposit = ts !== 0 ? new Date(ts).toISOString() : null;

    return {
        user_multiplier,
        last_user_deposit,
        has_stake,
        claimed_reward,
        user_shares,
        can_claim,
        earned_reward,
        total_staked: pool.totalDeposit.toString(),
        user_stake,
    };
};

const getNFTPool = async (
    current: NFTStakingPool,
    account: string,
    staking: Contract,
) => {
    const { contract_idx, input_token } = current;
    const [
        [pool],
        [user_multiplier],
        user,
        [earned_reward],
        [can_claim],
        [available],
        [staked],
    ] = await multiCall([
        {
            func_name: 'poolExt',
            target: staking.address,
            params: [contract_idx],
            iface: staking.interface,
        },
        {
            func_name: 'calcMultiplier',
            target: staking.address,
            params: [contract_idx, account],
            iface: staking.interface,
        },
        {
            func_name: 'userExt',
            target: staking.address,
            params: [contract_idx, account],
            iface: staking.interface,
        },
        {
            func_name: 'payout',
            target: staking.address,
            params: [contract_idx, account],
            iface: staking.interface,
        },
        {
            func_name: 'canClaim',
            target: staking.address,
            params: [contract_idx, account],
            iface: staking.interface,
        },
        {
            func_name: 'walletOfOwner',
            target: staking.address,
            params: [contract_idx, account],
            iface: staking.interface,
        },
        {
            func_name: 'getDepositedIdsOfUser',
            target: staking.address,
            params: [contract_idx, account],
            iface: staking.interface,
        },
    ]);

    const nft = getERC721Contract(input_token.address);

    const [available_tokens, staked_tokens] = await Promise.all([
        Promise.all(
            available.map((id: BigNumber) => mapNFT(id.toNumber(), nft)),
        ),
        Promise.all(staked.map((id: number) => mapNFT(id, nft))),
    ]);

    const ts = user.common.depositTime * 1000;
    const user_stake = user.common.totalInvested.toString();
    const claimed_reward = user.common.totalClaimed.toString();
    const has_stake = BigNumber.from(user_stake).gt(0);
    const last_user_deposit = ts !== 0 ? new Date(ts).toISOString() : null;

    return {
        user_multiplier,
        last_user_deposit,
        has_stake,
        claimed_reward,
        user_stake,
        can_claim,
        earned_reward,
        total_staked: pool.totalInvested.toString(),
        available_tokens,
        staked_tokens,
    };
};

const mapNFT = async (id: number, contract: Contract) => {
    const uri = await contract.tokenURI(id);
    return axios.get(uri).then(({ data: { data, image } }) => ({
        id: id,
        data: image || data,
    }));
};

export const getLiquidityPool = async (
    current: LiquidityStakingPool,
    account: string,
    staking: Contract,
) => {
    const { contract_idx, pair } = current;
    const pair_iface = getContractInterface(ContractType.Pair);

    const [
        pool,
        [user_multiplier],
        user,
        [reward],
        [can_claim],
        reserves,
        [total_lps],
    ] = await multiCall([
        {
            func_name: 'pools',
            target: staking.address,
            params: [contract_idx],
            iface: staking.interface,
        },
        {
            func_name: 'calcMultiplier',
            target: staking.address,
            params: [contract_idx, account],
            iface: staking.interface,
        },
        {
            func_name: 'users',
            target: staking.address,
            params: [contract_idx, account],
            iface: staking.interface,
        },
        {
            func_name: 'payout',
            target: staking.address,
            params: [contract_idx, account],
            iface: staking.interface,
        },
        {
            func_name: 'canClaim',
            target: staking.address,
            params: [contract_idx, account],
            iface: staking.interface,
        },
        {
            func_name: 'getReserves',
            target: pair,
            iface: pair_iface,
        },
        {
            func_name: 'totalSupply',
            target: pair,
            iface: pair_iface,
        },
    ]);

    const ts = user.depositTime * 1000;
    const last_user_deposit = ts !== 0 ? new Date(ts).toISOString() : null;

    const token0_amount = reserves.reserve0
        .mul(pool.totalInvested)
        .div(total_lps);
    const token1_amount = reserves.reserve1
        .mul(pool.totalInvested)
        .div(total_lps);

    const user_token0_amount = reserves.reserve0
        .mul(user.totalInvested)
        .div(total_lps);
    const user_token1_amount = reserves.reserve1
        .mul(user.totalInvested)
        .div(total_lps);

    return {
        total_staked: token0_amount,
        second_total_staked: token1_amount,
        user_stake: user_token0_amount,
        second_user_stake: user_token1_amount,
        earned_reward: reward.toString(),
        claimed_reward: user.totalClaimed.toString(),
        last_user_deposit,
        can_claim,
        user_multiplier: user_multiplier.toString(),
        lp_amount: user.totalInvested.toString(),
        has_stake: user.totalInvested.gt(0),
    };
};
