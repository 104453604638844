import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { is_browser } from '@src/constants';

import { GRAPHQL_ENDPOINT } from '../config';

const httpLink = createHttpLink({
    uri: GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = is_browser && localStorage.getItem('decubate_access_token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

export const apollo_client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});
