import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LayoutState {
    connection_error_modal_open: boolean;
    incorrect_network_modal_open: boolean;
}

const initialState = {
    connection_error_modal_open: false,
    incorrect_network_modal_open: false,
} as LayoutState;

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setConnectionErrorModalOpen(state, action: PayloadAction<boolean>) {
            state.connection_error_modal_open = action.payload;
        },
        setIncorrectNetworkModalOpen(state, action: PayloadAction<boolean>) {
            state.incorrect_network_modal_open = action.payload;
        },
    },
});

export const { setConnectionErrorModalOpen, setIncorrectNetworkModalOpen } =
    layoutSlice.actions;
export default layoutSlice.reducer;
