import {
    Action,
    PayloadAction,
    ThunkDispatch,
    createSlice,
} from '@reduxjs/toolkit';

import { RootState } from '@src/bootstrap/store';
import { StakingPool } from '@src/ts/interfaces';
import { getContractByAddress } from '@src/contracts';
import { contract_types } from '@src/constants';
import { getPool } from './utils';

const opts = ['Deposit', 'Withdraw'];

interface StakingState {
    loading: boolean;
    current_pool: number;
    pools: StakingPool[];
    modal_state: string;
    deposit_amount: string;
    second_deposit_amount: string;
    withdraw_percent: string;
    selected_ids: number[];
    staking_modal_open: boolean;
}

const initialState = {
    loading: false,
    current_pool: 0,
    pools: [],
    modal_state: opts[0],
    deposit_amount: '0',
    second_deposit_amount: '0',
    withdraw_percent: '50',
    selected_ids: [],
    staking_modal_open: false,
} as StakingState;

const stakingSlice = createSlice({
    name: 'staking',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setCurrentPool(state, action: PayloadAction<number>) {
            state.current_pool = action.payload;
        },
        setPool(state, action: PayloadAction<StakingPool>) {
            const idx = state.pools.findIndex(
                ({ id }) => id === action.payload.id,
            );
            state.pools[idx] = action.payload;
        },
        setPools(state, action: PayloadAction<StakingPool[]>) {
            state.pools = action.payload;
        },
        setSelectedIds(state, action: PayloadAction<number[]>) {
            state.selected_ids = action.payload;
        },
        setModalState(state, action: PayloadAction<string>) {
            state.selected_ids = [];
            state.modal_state = action.payload;
        },
        setDepositAmount(state, action: PayloadAction<string>) {
            state.deposit_amount = action.payload;
        },
        setSecondDepositAmount(state, action: PayloadAction<string>) {
            state.second_deposit_amount = action.payload;
        },
        setWithdrawPercent(state, action: PayloadAction<string>) {
            state.withdraw_percent = action.payload;
        },
        setStakingDrawerOpen(state, action: PayloadAction<boolean>) {
            state.selected_ids = [];
            state.staking_modal_open = action.payload;
        },
    },
});

export const updatePool = (
    id: number,
    account: string,
    initial_pool?: StakingPool,
): ((
    dispatch: ThunkDispatch<RootState, void, Action>,
    getState: () => RootState,
) => void) => {
    return (
        dispatch: ThunkDispatch<RootState, void, Action>,
        getState: () => RootState,
    ): void => {
        dispatch(setLoading(true));

        const pool =
            initial_pool || getState().staking.pools.find((p) => p.id === id);

        const staking = getContractByAddress(
            pool.address,
            contract_types[pool.type],
        );

        getPool(pool, account, staking).then((user_data) => {
            dispatch(setPool({ ...pool, ...user_data }));
            dispatch(setLoading(false));
        });
    };
};

export const updatePools = (
    account?: string,
    load = true,
    initial_pools?: StakingPool[],
): ((
    dispatch: ThunkDispatch<RootState, void, Action>,
    getState: () => RootState,
) => void) => {
    return (
        dispatch: ThunkDispatch<RootState, void, Action>,
        getState: () => RootState,
    ): void => {
        if (load) {
            dispatch(setLoading(true));
        }
        const pools = initial_pools || getState().staking.pools;

        Promise.all(
            pools.map(async (p) => {
                if (account) {
                    const staking = getContractByAddress(
                        p.address,
                        contract_types[p.type],
                    );

                    const user_data = await getPool(p, account, staking);
                    return user_data;
                }

                return {};
            }),
        )
            .then((all_user_data) => {
                const updated_pools = pools.map((p, idx) => ({
                    ...p,
                    ...all_user_data[idx],
                }));
                dispatch(setPools(updated_pools));
                dispatch(setLoading(false));
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };
};

export const {
    setCurrentPool,
    setLoading,
    setPool,
    setPools,
    setModalState,
    setDepositAmount,
    setSelectedIds,
    setStakingDrawerOpen,
    setSecondDepositAmount,
    setWithdrawPercent,
} = stakingSlice.actions;
export default stakingSlice.reducer;
