import { ContractType } from '@src/ts/constants';

import { getContract } from '../contracts';
import { Interface, Result } from '@ethersproject/abi';

export interface Call {
    target: string;
    func_name: string;
    iface?: Interface;
    params?: unknown[];
}

const multicall = getContract(ContractType.MultiCall);

export const multiCall = async (
    calls: Call[],
    default_iface?: Interface,
): Promise<Result[]> => {
    const mapped_calls = calls.map(
        ({ iface = default_iface, target, func_name, params = [] }) => ({
            target,
            callData: iface.encodeFunctionData(func_name, params),
        }),
    );
    const res = await multicall.callStatic.aggregate(mapped_calls);
    return calls.map(({ iface = default_iface, func_name }, idx) =>
        iface.decodeFunctionResult(func_name, res.returnData[idx]),
    );
};
