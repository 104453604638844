import { NETWORKS } from '@src/networks';
import { StakingModule } from './ts/interfaces';

export const CHAIN_ID = parseInt(process.env.REACT_CHAIN_ID, 10) || 97;

const getRandomInt = (max: number) => Math.floor(Math.random() * max);

export const NETWORK = NETWORKS[CHAIN_ID];

export const getRPCUrl = (): string => {
    const url_list = NETWORK.rpc;
    return url_list[getRandomInt(url_list.length)];
};

export const GRAPHQL_ENDPOINT =
    process.env.REACT_GRAPHQL_ENDPOINT ||
    'https://0ig9c0tdra.execute-api.eu-central-1.amazonaws.com/dev/graphql';

export const DEFAULT_TOKEN = JSON.parse(
    process.env.REACT_DEFAULT_TOKEN || '{}',
);

export const COMPANY_LOGO = process.env.REACT_COMPANY_LOGO;
export const COMPANY_LINK = process.env.REACT_COMPANY_LINK || '/';
export const LOGO_WIDTH = process.env.REACT_LOGO_WIDTH;
export const COMPANY_NAME = process.env.REACT_COMPANY_NAME;
export const DESKTOP_BANNER = process.env.REACT_BANNER_DESKTOP;
export const MOBILE_BANNER = process.env.REACT_BANNER_MOBILE;
export const BANNER_LINK = process.env.REACT_BANNER_LINK;
export const DISABLE_DECUBATE_FOOTER =
    process.env.REACT_DISABLE_DECUBATE_FOOTER === 'true';
export const LEGACY_COMPOUND = process.env.REACT_LEGACY_COMPOUND === 'true';
export const FOOTER_LINKS = JSON.parse(process.env.REACT_FOOTER_LINKS || '[]');

export const ONBOARD_LOGO = process.env.REACT_ONBOARD_LOGO;
export const ACCOUNT_IMAGE = process.env.REACT_ACCOUNT_IMAGE;
export const PLATFORM_IMAGE = process.env.REACT_PLATFORM_IMAGE;

export const MULTICALL = process.env.REACT_MULTICALL;
export const LOGO_STORE = process.env.REACT_LOGO_STORE;
export const COMPOUND_STAKING = process.env.REACT_COMPOUND_STAKING;
export const MULTI_ASSET_STAKING = process.env.REACT_MULTI_ASSET_STAKING;
export const COMPOUNDER = process.env.REACT_COMPOUNDER;

export const TERMS = process.env.REACT_TERMS;
export const PRIVACY = process.env.REACT_PRIVACY;

export const DEV_WALLETS: string[] =
    JSON.parse(process.env.REACT_DEV_WALLETS || '[]') || [];

const theme = JSON.parse(process.env.REACT_THEME || '{}');

export const ACCENT_COLOR = theme.accent;
export const IS_DARK_MODE = theme.is_dark_mode;
export const LEVEL_ZERO_COLOR = theme.main;
export const LEVEL_ONE_COLOR = theme.level_one;
export const LEVEL_TWO_COLOR = theme.level_two;
export const CORNER_ROUNDING = {
    none: '0',
    sm: '0.125rem',
    md: '0.375rem',
    lg: '0.5rem',
    full: '9999px',
}[theme.rounded_corners];
export const STAKING_MODULES: StakingModule[] =
    JSON.parse(process.env.REACT_STAKING_MODULES || '[]') || [];
