import React, { useEffect, useState } from 'react';
import { useConnectWallet, useSetChain } from '@web3-onboard/react';
import {
    ConnectedChain,
    ConnectOptions,
    DisconnectOptions,
    WalletState,
} from '@web3-onboard/core';
import { Web3Provider as EthersWeb3Provider } from '@ethersproject/providers';
import { CHAIN_ID } from '@src/config';

interface IWeb3Context {
    wallet: WalletState;
    account?: string;
    connecting: boolean;
    connect: (options: ConnectOptions) => Promise<WalletState[]>;
    disconnect: (wallet: DisconnectOptions) => Promise<WalletState[]>;
    connectedChain: ConnectedChain;
    settingChain: boolean;
    provider?: EthersWeb3Provider;
}

const Web3Context = React.createContext<IWeb3Context>(null);

export const Web3onboardProvider: React.FC = ({ children }) => {
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
    const [{ connectedChain, settingChain }, setChain] = useSetChain();
    const [prev_account, setPrevAccount] = useState('');
    const account = wallet && wallet.accounts[0]?.address;

    useEffect(() => {
        const previouslyConnectedWallets = JSON.parse(
            window.localStorage.getItem('connectedWallets'),
        );

        if (previouslyConnectedWallets?.length > 0) {
            // You can also auto connect "silently" and disable all onboard modals to avoid them flashing on page load

            connect({
                autoSelect: {
                    label: previouslyConnectedWallets[0],
                    disableModals: true,
                },
            });
        }
    }, []);

    useEffect(() => {
        if (
            account &&
            account !== prev_account &&
            parseInt(connectedChain?.id, 16) !== CHAIN_ID
        ) {
            setChain({ chainId: '0x' + CHAIN_ID.toString(16) });
        }
        setPrevAccount(account);
    }, [account, connectedChain?.id]);

    return (
        <Web3Context.Provider
            value={{
                wallet,
                account,
                connecting,
                connect,
                disconnect,
                connectedChain,
                settingChain,
                provider: wallet?.provider
                    ? new EthersWeb3Provider(wallet.provider)
                    : undefined,
            }}
        >
            {children}
        </Web3Context.Provider>
    );
};

function useWeb3Onboard(): IWeb3Context {
    return React.useContext(Web3Context);
}

export { useWeb3Onboard };
