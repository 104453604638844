export enum ContractType {
    ERC20 = 'ERC20',
    ERC721 = 'ERC721',
    CompoundStaking = 'CompoundStaking',
    LiquidityStaking = 'LiquidityStaking',
    LogoStore = 'LogoStore',
    NFTStaking = 'NFTStaking',
    MultiCall = 'MultiCall',
    Vault = 'Vault',
    Pair = 'Pair',
}
