import { Interface } from '@ethersproject/abi';
import { isAddress } from '@ethersproject/address';
import { Contract, ContractInterface } from '@ethersproject/contracts';

import { provider } from '@src/bootstrap/web3';
import { LEGACY_COMPOUND, MULTICALL, LOGO_STORE } from '@src/config';

import { ContractType } from '@src/ts/constants';

import CompoundStaking from './abi/CompoundStaking.json';
import LegacyCompound from './abi/LegacyCompound.json';
import ERC20 from './abi/ERC20.json';
import ERC721 from './abi/ERC721.json';
import LogoStore from './abi/LogoStore.json';
import NFTStaking from './abi/NFTStaking.json';
import MultiCall from './abi/MultiCall.json';
import Vault from './abi/Vault.json';
import Liquidity from './abi/LiqLocker.json';
import Pair from './abi/Pair.json';

export const contract = {
    [ContractType.ERC20]: {
        abi: ERC20,
        address: '',
    },
    [ContractType.CompoundStaking]: {
        abi: LEGACY_COMPOUND ? LegacyCompound : CompoundStaking,
        address: '',
    },
    [ContractType.LiquidityStaking]: {
        abi: Liquidity,
        address: '',
    },
    [ContractType.LogoStore]: {
        abi: LogoStore,
        address: LOGO_STORE,
    },
    [ContractType.MultiCall]: {
        abi: MultiCall,
        address: MULTICALL,
    },
    [ContractType.Vault]: {
        abi: Vault,
        address: '',
    },
    [ContractType.NFTStaking]: {
        abi: NFTStaking,
        address: '',
    },
    [ContractType.ERC721]: {
        abi: ERC721,
        address: '',
    },
    [ContractType.Pair]: {
        abi: Pair,
        address: '',
    },
};

export const getContract = (type: ContractType): Contract => {
    const { address, abi } = contract[type];
    return new Contract(address, abi, provider);
};

export const newContract = (
    address: string,
    abi: ContractInterface,
): Contract => new Contract(address, abi, provider);

export const getContractByAddress = (
    address: string,
    type: ContractType,
): Contract => {
    if (!isAddress(address?.toLowerCase())) return null;
    return new Contract(address, contract[type].abi, provider);
};

export const getERC20Contract = (address: string): Contract => {
    if (!isAddress(address?.toLowerCase())) return null;
    return new Contract(address.toLowerCase(), ERC20, provider);
};

export const getERC721Contract = (address: string): Contract => {
    if (!isAddress(address?.toLowerCase())) return null;
    return new Contract(address.toLowerCase(), ERC721, provider);
};

export const getABI = (type: ContractType) => contract[type].abi;

export const getContractInterface = (type: ContractType): Interface =>
    new Interface(contract[type].abi);
